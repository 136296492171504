import React from "react";
import theme from "theme";
import { Theme, Text, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"sartlar-ve-kosullar"} />
		<Helmet>
			<title>
				PREMIER LEGAL SOLUTIONS
			</title>
			<meta name={"description"} content={"İhtiyaçlarınıza Özel Güvenilir ve Etkili Hukuk Çözümlerindeki Uzmanlığımızı Keşfedin."} />
			<meta property={"og:title"} content={"PREMIER LEGAL SOLUTIONS"} />
			<meta property={"og:description"} content={"İhtiyaçlarınıza Özel Güvenilir ve Etkili Hukuk Çözümlerindeki Uzmanlığımızı Keşfedin."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			{"    "}{"    "}
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				{"        "}Şartlar ve Koşullar{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				{"        "}PREMIER LEGAL SOLUTIONS web sitesini kullanmadan önce lütfen aşağıdaki şartlar ve koşulları dikkatlice okuyunuz. Bu web sitesini kullanarak, bu şartlar ve koşulları kabul etmiş olursunuz.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Hizmetlerin Kullanımı{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Bu web sitesi, genel bilgi sağlama amacıyla sunulmaktadır ve burada yer alan içerik yalnızca bilgilendirme amaçlıdır. PREMIER LEGAL SOLUTIONS, web sitesindeki bilgilerin doğruluğunu, eksiksizliğini veya güncelliğini garanti etmez. Web sitesinde sunulan hizmetler, önceden bildirimde bulunmaksızın değiştirilebilir.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Fikri Mülkiyet Hakları{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Bu web sitesindeki tüm içerikler, yazılımlar, grafikler, metinler, logolar ve diğer materyaller, PREMIER LEGAL SOLUTIONS'a aittir ve telif hakkı, ticari marka ve diğer fikri mülkiyet yasalarıyla korunmaktadır. Bu materyallerin izinsiz kullanımı, çoğaltılması, dağıtılması veya başka bir şekilde kullanılması yasaktır.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Sorumluluğun Sınırlandırılması{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}PREMIER LEGAL SOLUTIONS, bu web sitesinin kullanımından doğacak herhangi bir doğrudan, dolaylı, tesadüfi, özel veya sonuç olarak ortaya çıkan zararlardan sorumlu değildir. Bu sorumluluk sınırlamaları, yasal olarak izin verilen en geniş kapsamda geçerli olacaktır.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Gizlilik{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Kişisel bilgilerinizin gizliliğine büyük önem veriyoruz. Kişisel verilerinizin toplanması, kullanılması ve korunması hakkında daha fazla bilgi edinmek için lütfen Gizlilik Politikamızı inceleyin.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Üçüncü Taraf Bağlantıları{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Web sitemiz, üçüncü taraf web sitelerine bağlantılar içerebilir. Bu bağlantılar yalnızca kolaylık sağlamak amacıyla sunulmuştur ve bu siteler üzerinde herhangi bir kontrolümüz bulunmamaktadır. Üçüncü taraf sitelere erişim ve bunların kullanımı sizin sorumluluğunuzdadır.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Yasal Uygunluk{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Bu şartlar ve koşullar, Türkiye Cumhuriyeti yasalarına tabidir ve bu yasalara göre yorumlanacaktır. Herhangi bir uyuşmazlık durumunda, İstanbul mahkemeleri ve icra daireleri yetkili olacaktır.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Değişiklikler{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}PREMIER LEGAL SOLUTIONS, bu şartlar ve koşulları herhangi bir zamanda değiştirme hakkını saklı tutar. Herhangi bir değişiklik yapıldığında, bu sayfada güncellenmiş şartlar ve koşullar yayınlanacaktır. Bu nedenle, bu sayfayı düzenli olarak kontrol etmenizi öneririz.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}İletişim{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Bu şartlar ve koşullar ile ilgili herhangi bir sorunuz varsa, bizimle şu iletişim bilgileri üzerinden iletişime geçebilirsiniz:{" "}
				<Link href="mailto:info@premierlegalsolutions.com">
					info@premierlegalsolutions.com
				</Link>
				{"    "}
			</Text>
		</Section>
		<Components.Newfooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661cef388e2e8e00217acf89"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});